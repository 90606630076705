<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="150px"
      :model="form"
      :rules="rules"
      ref="saveForm"
      label-position="top"
    >
      <el-form-item label="会员籍贯地区设置：">
        <el-radio
          v-model="form.person_native_place_setting.is_custom"
          :label="0"
          >默认</el-radio
        >
        <el-radio
          v-model="form.person_native_place_setting.is_custom"
          :label="1"
          >自定义</el-radio
        >
      </el-form-item>
      <el-form-item
        v-if="form.person_native_place_setting.is_custom"
        label="会员籍贯可选地区："
        prop="person_native_place_setting.data"
      >
        <el-cascader
          v-model="form.person_native_place_setting.data"
          :options="areaData"
          :props="{ multiple: true, expandTrigger: 'hover', value: 'label' }"
          collapse-tags
          clearable
        ></el-cascader>
      </el-form-item>

      <el-form-item label="企业所属地区设置：">
        <el-radio v-model="form.company_region_setting.is_custom" :label="0"
          >默认</el-radio
        >
        <el-radio v-model="form.company_region_setting.is_custom" :label="1"
          >自定义</el-radio
        >
      </el-form-item>
      <el-form-item
        v-if="form.company_region_setting.is_custom"
        label="企业所属可选地区："
        prop="company_region_setting.data"
      >
        <el-cascader
          v-model="form.company_region_setting.data"
          :options="areaData"
          :props="{ multiple: true, expandTrigger: 'hover', value: 'label' }"
          collapse-tags
          clearable
        ></el-cascader>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
// 接口
import { getRegionSetting, setRegionSetting } from '../api/list.js'
import areaData from '@/modules/common/json/address.json'
export default {
  components: { FixedActionBar,  },
  data() {
    return {
      form: {
        person_native_place_setting: {
          is_custom: 0,
          data: [],
        },
        company_region_setting: {
          is_custom: 0,
          data: [],
        },
      },
      loading: false,
      // 商品类型选项
      // 校验规则
      rules: {
        'person_native_place_setting.data':[{ required: true, message: '请选择地区', trigger: 'change' }],
        'company_region_setting.data': [{ required: true, message: '请选择地区', trigger: 'change' }],
      },
      areaData,
    }
  },
  methods: {
    // 保存配置
    saveConfig(status) {
      let postData = {
        config: {
          company_region_setting: {
            is_custom: this.form.company_region_setting.is_custom,
            data: this.convertJsonArray(this.form.company_region_setting.data),
          },
          person_native_place_setting: {
            is_custom: this.form.person_native_place_setting.is_custom,
            data: this.convertJsonArray(this.form.person_native_place_setting.data),
          },
        },
      }
      // 保存商城数据
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true

          setRegionSetting(postData)
            .then((res) => {
              this.$message.success(res.msg)

              this.getDatail()

              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 获取详情
    getDatail() {
      getRegionSetting()
        .then((res) => {
          this.form = res.data
          this.form.company_region_setting.data = this.convertToNestedArray(
            res.data.company_region_setting.data
          )
          this.form.person_native_place_setting.data =
            this.convertToNestedArray(res.data.person_native_place_setting.data)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    convertJsonArray(arr) {
      let result = []
      arr.forEach(([city, district, area]) => {
        let cityExists = false
        let districtExists = false
        result.forEach((item) => {
          if (item.label === city) {
            cityExists = true

            item.children.forEach((subItem) => {
              if (subItem.label === district) {
                districtExists = true
                subItem.children.push({ label: area })
              }
            })

            if (!districtExists) {
              item.children.push({
                label: district,
                children: [{ label: area }],
              })
            }
          }
        })
        if (!cityExists) {
          result.push({
            label: city,
            children: [{ label: district, children: [{ label: area }] }],
          })
        }
      })
      return result
    },
    convertToNestedArray(data) {
      let result = []
      data.forEach((item) => {
        let city = item.label
        item.children.forEach((subItem) => {
          let district = subItem.label
          subItem.children.forEach((area) => {
            result.push([city, district, area.label])
          })
        })
      })

      return result
    },
  },
  mounted() {
    this.getDatail()
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}
</style>
